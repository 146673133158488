.table-filter {
  .ant-btn {
    height: 50px;
  }

  .ant-select-lg {
    height: 50px;
  }

  .ant-select-selector {
    height: 50px !important;
  }

  .ant-select-selection-item {
    font-weight: 700;
    font-size: medium;
    margin-top: 5px;
  }
}
