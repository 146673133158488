// Tailwind Preflight fixes
button[type='button'].ant-btn-primary  {
    background: @primary-color;
}
button[type='button'].ant-btn-primary:disabled {
  background: #f5f5f5;
}
button[type='submit'].ant-btn-primary  {
  background: @primary-color;
}
button[type='submit'].ant-btn-primary:disabled {
  background: #f5f5f5;
}

button[type='button'].ant-switch  {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(to right, #fff, #fff)
}
button[type='button'].ant-switch:disabled {
  background: #f5f5f5;
}
button[type='button'].ant-switch-checked  {
  background: @primary-color;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner  {
  color: white
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


// Layout
.ant-layout.root {
  min-height: 100vh;
}
.ant-layout-content {
  background-color: @body-background;
  padding: 24px 36px;
}

// Slider
.ant-layout-sider {
  border-right: solid 1px @c-border;
  user-select: none;

  .ant-menu-item-only-child {
    // Overwriting antd inline style
    padding-left: 58px !important;
  }

  .ant-menu-item-selected,
  .ant-menu-item:active {
    background-color: @c-blue;
    a {
      color: @white;
    }
    svg {
      fill: @white;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: @c-blue;
  }

  .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-menu-item-selected {
    display: flex;
    align-items: center;

    transition: none;

    .anticon {
      margin-right: 15px;
    }
  }

  .ant-menu-submenu-title:active {
    background-color: @white;
    color: @menu-item-color;
  }

  .ant-menu-submenu-selected {
    color: @menu-item-color;
  }
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

// Collapse

.ant-collapse-content-box {
  max-height: 450px;
  overflow: scroll;
}

// Modal
.ant-modal-root {
  .modal-promotion {
    .ant-modal-content {
      width: 100%;
    }
  }
}
.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .ant-modal-content {
    width: 800px;
  }

  .ant-modal-body {
    overflow: auto;
    max-height: 80vh;
  }

  .ant-modal-footer {
    display: none;
  }
}

// Steps component
.ant-steps {
  background: @white;
  // width: fit-content;

  .ant-steps-item-process,
  .ant-steps-item-wait,
  .ant-steps-item-finish {
    width: 133px;
  }
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: transparent;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: @steps-white-color;
}
.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
  background: @c-blue;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: @c-dark-blue;
}
.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: @white;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: @c-blue;
  background: @steps-white-color;
}

.ant-steps-navigation .ant-steps-item {
  &::before,
  &::after {
    display: none;
  }
}

// Radio button group
.ant-radio-button-wrapper {
  margin: 0 8px 4px 0;
  border: 1px solid @primary-color;
  border-radius: @border-radius-base;

  &:not(:first-child)::before {
    display: none;
  }

  &:first-child {
    margin-left: 0;
  }

  &:first-child,
  &:last-child {
    border-left: 1px solid @primary-color;
    border-radius: @border-radius-base;
  }
}

// Table
.ant-table-wrapper {
  .ant-table-cell {
    padding: 10px 15px;
  }

  .ant-table-row {
    height: 65px;
  }
}

// Description
.ant-descriptions-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

// Input
.ant-input-affix-wrapper {
  height: 50px;
  padding: 0 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15);
}

// Select
.ant-select {
  border-radius: 4px;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: white;
}

// Switch
.ant-switch-checked .ant-switch-handle {
  left: calc(55%);
}

// Transfer
.ant-transfer-list-content-item-checked {
  background-color: #d0d0d0;
}

// List
.ant-list {
  background-color: @white;
  border-color: @c-border;
  border-radius: 4px;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15);
}

.ant-list-header {
  font-size: 1.2em;
  font-weight: 500;
}

// Card
.ant-card {
  border: 1px solid @c-border;
  border-radius: 4px;
}

.ant-card-cover {
  text-align: center;
}

.ant-card-head {
  border-bottom: 1px solid @c-border;
}

// Form
.ant-form-item-label {
  font-weight: 500;
}



.ant-image-error {
  width: 100%;
  height: auto;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}