.priceList-title {
  background-color: #fff;
  border-color: #cfd8dc;
  border-radius: 4px;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15);
  margin-bottom: 10px;
  padding: 5px 0;
  color: #263238;
  font-size: 1.2em;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  font-weight: 500;
  text-align: center;
}
