
.skeleteon-container {
  width: 80%;
  margin: auto;

  .ant-skeleton-paragraph {
    margin-top: 35px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    margin: 32px 0px;
  }
}

.sidebar-header-wrapper {
  .sidebar-header-content {
    display: flex;
    align-items: center;
    height: 100px;

    p {
      margin: 0;
    }

    .sidebar-header-avatar {
      margin: 0px 16px;
    }

    .sidebar-header-text {
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ant-divider,
  .ant-divider-horizontal {
    margin: 0;
  }

  .ant-skeleton {
    height: 100px;
    width: 80%;
    margin: auto;
  }

  .ant-skeleton-header {
    vertical-align: middle;
  }

  .ant-skeleton-content {
    vertical-align: middle;
  }
}

.retail-experience {
  display: flex;
  height: 40px;
  cursor: pointer;

  .exit-retailer-view {
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 130px;
  }

  .anticon {
    margin: 0px 16px 0px 27px;
  }
}