.activeSlider {
  transform: scale(1.2);
  transition: all 0.3s ease-out;
}
.list-img_container {
  width: 25%;
  margin-right: 15px;
  cursor: pointer;
  img {
    transition: all 0.3s ease-out;
  }
}
.slider-container {
  background-color: #fff;
  border: 1 px solid #cfd8dc;
  border-radius: 4 px;
  padding: 10px;
  .slider-img_container {
    min-height: 300px;
    position: relative;
    .close-btn {
      position: absolute;
      cursor: pointer;
      top: -10px;
      right: 0;
      font-size: 32px;
      transform: rotate(45deg);
    }
  }
}
