@tailwind base;
@tailwind components;
@tailwind utilities;

/* html,
body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
} */