.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.ant-statistic {
  .ant-statistic-title {
    margin-bottom: -10px;
    margin-left: 2px;
  }
  .ant-statistic-content {
    // color: #546E7A;
    font-size: 20px;
  }
}


@keyframes fadeIn {
  from {
    height: 0px;
  }
  to {
    height: 50px;
  }
}
