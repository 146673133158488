/** Paddings */
.p0 {
  padding: 0px;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p25 {
  padding: 25px;
}
.pb0 {
  padding-bottom: 0px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pr0 {
  padding-right: 0px;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pl0 {
  padding-left: 0px;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pt0 {
  padding-top: 0px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}

/** Margins */
.m-auto {
  margin: auto;
}
.m0 {
  margin: 0px;
}
.m5 {
  margin: 5px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.mb0 {
  margin-bottom: 0px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mr0 {
  margin-right: 0px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.ml0 {
  margin-left: 0px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.mt0 {
  margin-top: 0px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}

/** Width */
.w0 {
  width: 0%;
}
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w50 {
  width: 50%;
}
.w55 {
  width: 55%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}

/** Text align */
.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}

/** Font settings */
.f-light {
  font-weight: 400;
}

/** Color */
.c-darkblue {
  color: @c-dark-blue;
}
.c-blue {
  color: @c-blue;
}
.c-green {
  color: @c-green;
}
.c-yellow {
  color: @c-yellow;
}
.c-yellow {
  color: @c-light-yellow;
}
.c-red {
  color: @c-red;
}
.c-white {
  color: @white;
}

/** Background color */
.bg-darkblue {
  background-color: @c-dark-blue;
}
.bg-blue {
  background-color: @c-blue;
}
.bg-green {
  background-color: @c-green;
}
.bg-yellow {
  background-color: @c-yellow;
}
.bg-light-yellow {
  background-color: @c-light-yellow;
}
.bg-red {
  background-color: @c-red;
}
.bg-white {
  background-color: @white;
}

/** Flex Box */
.d-fc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.direction-column {
  flex-direction: column;
}

/** Common */
.cursor-pointer {
  cursor: pointer;
}

.strike {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}

/** Border */
.b-r4 {
  border-radius: 4px;
}
.b {
  border: 1px solid @c-border;
}

.br {
  border-right: 1px solid @c-border;
}

.bl {
  border-left: 1px solid @c-border;
}

@desktop: ~'(max-width: 1145px)';
.horizontal-line {
  color: black;
  background-color: black;
  height: 2px;
  width: 10px;
  @media @desktop {
    visibility: hidden;
  }
}
