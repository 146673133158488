.input-number-wrapper {
  display: flex;
  margin: 0 10px;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  height: 25px;

  .input-number-input {
    width: 30px;
    border: none;
    border-left: 1px solid #cfd8dc;
    border-right: 1px solid #cfd8dc;
    text-align: center;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .input-number-button {
    width: 20px;
    background: white;
    border: none;
    color: black !important;
    cursor: pointer;
  }
}