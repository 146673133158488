.product-list {
  .ant-list {
    background-color: transparent;
    border-radius: none;
    box-shadow: none;
  }

  .ant-col {
    height: 100%;
    .ant-list-item {
      height: 97.5%;
    }
  }
}

.product-list-item {
  .ant-card {
    border-radius: 0;
  }
  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    min-height: 0;
    text-align: center;
  }

  .ant-card-head-title {
    font-size: 11px;
    padding: 7px 0;
  }

  .ant-card-actions {
    li {
      margin: 0;
    }
  }
}

.product-brand-logo {
  display: inline-block;
  border-radius: 4px;
  padding: 0 10px;
  text-align: center;
  color: white;
  background-color: #001780;
  margin-right: 10px;
}

.prise-list-item {
  .ant-carousel {
    .slick-dots li.slick-active button {
      background: rgb(0, 0, 0);
    }
    .slick-next {
      right: -20px;
      &::before {
        content: '';
      }
    }
    .slick-prev {
      left: -20px;

      &::before {
        content: '';
      }
    }
  }

  .dotsClass {
    li {
      background: #739ea8;
    }
  }
}
