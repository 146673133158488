.invoice-header {
  display: flex;
  position: relative;
  &-print-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      transform: scale(1.3);
    }
  }
  p {
    margin: 0;
  }
  &_leftSide {
    width: 50%;
    &_address {
      padding-left: 28px;
    }
  }
  &_rightSide {
    width: 50%;

    &-title {
      font-size: 24px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
}
.invoice-bill-Ship-Address {
  display: flex;
  .address-border {
    border-left: 2px solid black;
    padding-left: 10px;
    margin-left: 10px;
    max-width: 40%;
  }
  .invoice-billTo {
    display: flex;
    align-items: center;

    flex: 0 0 50%;
    padding-left: 28px;
  }
  .invoice-shipTo {
    display: flex;
    flex: 0 0 45%;
    align-items: center;
  }
}
.invoice-table {
  margin-top: 20px;
  width: 100%;
  td {
    text-align: center;
  }
  tr {
    height: 50px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #cfcfcf;
    }
  }
  thead {
    border-bottom: 1px solid #cfcfcf;
  }
}
.invoice-price-table {
  width: 50%;
  margin-left: auto;
  margin-top: 20px;
  tr {
    height: 50px;
    border-top: 1px solid #cfcfcf;

    td {
      text-align: right;
      padding-right: 10px;
    }
  }
}

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }
  .invoice-header {
    &-print-icon {
      display: none;
      width: 0;
      visibility: hidden;
    }
  }
  .ant-layout-sider,
  .ant-layout-header {
    display: none;
  }
  * {
    background-image: none;
  }

  // img,
  svg {
    display: none;
  }
  .invoice-bill-Ship-Address {
    display: flex;
    .address-border {
      max-width: 60%;
    }
  }
}
