.retail-stores {
  .ant-list {
    border: none;
    box-shadow: none;
  }

  .ant-card-head {
    padding-right: 10;
    padding-right: 0;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-actions > li {
    color: #000;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.expandable-box {
  padding: 0 24px;

  &-header {
    display: flex;
    justify-content: space-between;
  }
  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }

  &__content {
    margin-top: 10px;
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    &__btn {
      border: none;
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      padding: 0;
      height: fit-content;
    }
  }
  &__icon {
    display: inline-flex;
    align-self: center;
    > svg {
      font-size: 1rem;
      transition: transform 0.3s ease-in-out;
      transform: rotate(0deg);
    }
  }
}

.expandable-box--opened {
  .expandable-box__icon {
    > svg {
      transform: rotate(180deg);
    }
  }

  .expandable-box__inner {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height;
  }

  .expandable-box__content {
    margin-top: 10px;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
    &__btn {
      display: inline;
      border: none;
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      padding: 0;
      height: fit-content;
    }
  }
}
.map-container {
  width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  position: sticky;
  top: 0;
  padding-left: 10px;
}
