.header-company-icon {
  .anticon {
    svg {
      vertical-align: middle;
    }
  }
}

.notification-read {
  opacity: 60%;
}

.notification-box {
  width: 450px;
  box-shadow: none;
}
