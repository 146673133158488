.product-list-item-card-container {
  height: 100%;

  .ant-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-card-body {
    height: 100%;
  }

  .product-list-item-card {
    position: relative;
    overflow: hidden;

    .pricing-drawer {
      position: absolute;
      .ant-drawer-content-wrapper {
        width: 100% !important;
      }
      .ant-drawer-body {
        padding-top: 10px;
        display: flex;
        flex-direction: column;

        .expander {
          height: 100%;
        }
      }
    }
  }
}
