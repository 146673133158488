.product-settings-modal {
  .ant-carousel {
    .slick-dots li.slick-active button {
      background: rgb(0, 0, 0);
    }
    .slick-next {
      right: -20px;
      &::before {
        content: '';
      }
    }
    .slick-prev {
      left: -20px;

      &::before {
        content: '';
      }
    }
  }

  .dotsClass {
    li {
      background: #739ea8;
    }
  }
}
