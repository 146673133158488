 .order-summary {
   .ant-divider-horizontal {
    margin: 20px 0;
   }
   .ant-card-body {
     padding-top: 5px;
   }
   .ant-list-split {
     box-shadow: none;
   }

   .ant-list-item {
     display: inherit;
   }
 }